<template>
  <div class="menu-body"
    v-if="$oidc.isAuthenticated && $oidc.userProfile && $oidc.userProfile.roles && $oidc.userProfile.roles.length > 0">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="asmt-con">
      <div class="asmt-info">
        <div class="asmt-name asmt-padding page-heading-h2 width-50">
          {{ this.pillarName }}
        </div>
        <div style="display: flex">
          <div class="plant-card top-card">
            <div class="info-card-lable">Target</div>
            <div class="info-card-value">{{ this.targetActions }}</div>
          </div>
          <div class="plant-card top-card">
            <div class="info-card-lable">Committed</div>
            <div class="info-card-value">{{ this.committedActions }}</div>
          </div>
          <div class="plant-card top-card">
            <div class="info-card-lable">Completed</div>
            <div class="info-card-value">{{ this.completedActions }}</div>
          </div>
        </div>
      </div>
      <div class="principle-dd">
        <label for="pillar" class="form-label page-heading-dropdown lable-text">Select Principle</label>
        <select class="form-control page-heading-dropdown-content principal-selecct" id="pillar" v-model="principleId"
          @change="(onchangePrinciple = 'principle'), practiseDetails($event.target.value)">
          <option selected>Select Principle</option>
        </select>
      </div>
      <div class="level-con">
        <div class="level-border">
          <b-form-radio-group id="radio-group-1" v-model="targetLevel" name="radio-options"
            style="display: inline-flex; width: 100%" @change="handleRadioChange($event)">
            <div class="page-heading-tab level-info level-br live-level" style="border-radius: 4px 0 0 4px" id="Level0">
              <label class="container">
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[0].statusName == 'Not Started' || practiseDetailsData[0].statusName == 'Not Started (Major Change)')"
                  src="../../../assets/maturity-not-start.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[0].statusName == 'On Track'"
                  src="../../../assets/blue-tick.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[0].statusName == 'Off Track'"
                  src="../../../assets/off-track.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[1].statusName == 'Deleted'"
                  src="../../../assets/deleted.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[0].statusName == 'Completed' || practiseDetailsData[0].statusName == 'Completed (Major Change)')"
                  src="../../../assets/maturity-cmpl.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[0].statusName == 'Not Sustained' || practiseDetailsData[0].statusName == 'Not Sustained (Major Change)')"
                  src="../../../assets/yellow-tick.svg" />

                Level 0
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[0].isMajorChange"
                  src="../../../assets/red-warning.svg" />
                <input type="radio" class="cursorpointer" name="some-radios" value="0" style="margin-right: 1rem"
                  @change="(levelId = 0), handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level1">
              <label class="container">
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[1].statusName == 'Not Started' || practiseDetailsData[1].statusName == 'Not Started (Major Change)')"
                  src="../../../assets/maturity-not-start.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[1].statusName == 'On Track'"
                  src="../../../assets/blue-tick.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[1].statusName == 'Off Track'"
                  src="../../../assets/off-track.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[1].statusName == 'Deleted'"
                  src="../../../assets/deleted.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[1].statusName == 'Completed' || practiseDetailsData[1].statusName == 'Completed (Major Change)')"
                  src="../../../assets/maturity-cmpl.svg" />
                <img alt="more" class="status-icons mr-02 ml-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[1].statusName == 'Not Sustained' || practiseDetailsData[1].statusName == 'Not Sustained (Major Change)')"
                  src="../../../assets/yellow-tick.svg" />

                Level 1
                <img alt="more" class="status-icons mr-02 ml-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[1].isMajorChange"
                  src="../../../assets/red-warning.svg" />
                <input type="radio" class="cursorpointer" name="some-radios" value="1" style="margin-right: 1rem"
                  @change="(levelId = 1), handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level2">
              <label class="container">
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[2].statusName == 'Not Started' || practiseDetailsData[2].statusName == 'Not Started (Major Change)')"
                  src="../../../assets/maturity-not-start.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[2].statusName == 'On Track'"
                  src="../../../assets/blue-tick.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[2].statusName == 'Off Track'"
                  src="../../../assets/off-track.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[1].statusName == 'Deleted'"
                  src="../../../assets/deleted.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[2].statusName == 'Completed' || practiseDetailsData[2].statusName == 'Completed (Major Change)')"
                  src="../../../assets/maturity-cmpl.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[2].statusName == 'Not Sustained' || practiseDetailsData[2].statusName == 'Not Sustained (Major Change)')"
                  src="../../../assets/yellow-tick.svg" />

                Level 2
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[2].isMajorChange"
                  src="../../../assets/red-warning.svg" />
                <input type="radio" class="cursorpointer" name="some-radios" value="2" style="margin-right: 1rem"
                  @change="(levelId = 2), handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level3">
              <label class="container">
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[3].statusName == 'Not Started' || practiseDetailsData[3].statusName == 'Not Started (Major Change)')"
                  src="../../../assets/maturity-not-start.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[3].statusName == 'On Track'"
                  src="../../../assets/blue-tick.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[3].statusName == 'Off Track'"
                  src="../../../assets/off-track.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[1].statusName == 'Deleted'"
                  src="../../../assets/deleted.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[3].statusName == 'Completed' || practiseDetailsData[3].statusName == 'Completed (Major Change)')"
                  src="../../../assets/maturity-cmpl.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[3].statusName == 'Not Sustained' || practiseDetailsData[3].statusName == 'Not Sustained (Major Change)')"
                  src="../../../assets/yellow-tick.svg" />
                Level 3
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[3].isMajorChange"
                  src="../../../assets/red-warning.svg" />
                <input type="radio" class="cursorpointer" name="some-radios" value="3" style="margin-right: 1rem"
                  @change="(levelId = 3), handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level4">
              <label class="container">
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[4].statusName == 'Not Started' || practiseDetailsData[4].statusName == 'Not Started (Major Change)')"
                  src="../../../assets/maturity-not-start.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[4].statusName == 'On Track'"
                  src="../../../assets/blue-tick.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[4].statusName == 'Off Track'"
                  src="../../../assets/off-track.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[1].statusName == 'Deleted'"
                  src="../../../assets/deleted.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[4].statusName == 'Completed' || practiseDetailsData[4].statusName == 'Completed (Major Change)')"
                  src="../../../assets/maturity-cmpl.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[4].statusName == 'Not Sustained' || practiseDetailsData[4].statusName == 'Not Sustained (Major Change)')"
                  src="../../../assets/yellow-tick.svg" />

                Level 4
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[4].isMajorChange"
                  src="../../../assets/red-warning.svg" />
                <input type="radio" class="cursorpointer" name="some-radios" value="4" style="margin-right: 1rem"
                  @change="(levelId = 4), handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info live-level" id="Level5" style="border-radius: 0 4px 4px 0">
              <label class="container">
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[5].statusName == 'Not Started' || practiseDetailsData[5].statusName == 'Not Started (Major Change)')"
                  src="../../../assets/maturity-not-start.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[5].statusName == 'On Track'"
                  src="../../../assets/blue-tick.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[5].statusName == 'Off Track'"
                  src="../../../assets/off-track.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[1].statusName == 'Deleted'"
                  src="../../../assets/deleted.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[5].statusName == 'Completed' || practiseDetailsData[5].statusName == 'Completed (Major Change)')"
                  src="../../../assets/maturity-cmpl.svg" />
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && (practiseDetailsData[5].statusName == 'Not Sustained' || practiseDetailsData[5].statusName == 'Not Sustained (Major Change)')"
                  src="../../../assets/yellow-tick.svg" />

                Level 5
                <img alt="more" class="status-icons mr-02 ml-02"
                  v-if="practiseDetailsData.length && practiseDetailsData[5].isMajorChange"
                  src="../../../assets/red-warning.svg" />
                <input type="radio" class="cursorpointer" name="some-radios" value="5" style="margin-right: 1rem"
                  @change="(levelId = 5), handleRadioChange($event.target.value)" />
              </label>
            </div>
          </b-form-radio-group>
        </div>
      </div>
      <div class="prp-info">
        <div class="module-title prp-name">
          {{ principleNumber + "." + levelId + " " + principleName }}
        </div>
        <div class="page-content-regular prp-name">
          <pre class="page-content-regular">{{ this.practiceDescription }}</pre>
        </div>
        <div class="module-title prp-name margin-top-1em">Guidelines</div>
        <!-- <div class="behavoir-des page-content-regular" id="guidelineDescription">
          {{ this.guidelineDesc }}
        </div> -->
        <div class="page-content-regular prp-name">
          <pre class="page-content-regular">{{ this.guidelineDesc }}</pre>
        </div>        
      </div>
      <div class="attch-con">
        <div class="module-title prp-name margin-top-1em">Attachments</div>
        <div class="row attch-row">
          <ul class="col-3 uploaded-items attch-col page-content-regular">
            <li class="uploaded-items-li" v-for="(eachattachment, i) in attachmentUrls" :key="i">
              <span class="uploaded-file-name">
                <a class="li-doc-class" @click="downloadItem(eachattachment)" :download="eachattachment.documentURL">{{
                  eachattachment.documentName + "." + eachattachment.documentType }}
                </a>
              </span>
              <img alt="more" class="attch-icon" src="../../../assets/download 2.svg" />
            </li>
          </ul>
        </div>
      </div>
      <div class="review-con margin-top-1em">
        <div class="row review-rox">
          <div class="review-col marginRight16Percentage" :class="{
              'col-12': !secondPartyVisibility,
              'col-6': secondPartyVisibility
            }">
            <div class="page-heading-tab take-assessment-page-heading-tab-bottom-padding">Plant Self Assessment / Regional
              Calibration</div>
            <div class="status-con marginBottom-2">
              <div class="status-col-1">
                <label class="page-content-regular"> Current Status: </label>
                &nbsp;
                <label class="take-assessment-page-heading-tab-font-weight">
                  <img alt="more" class="status-icons mr-02 pr-st-icon" v-if="iconStaus == 'Not Started'"
                    src="../../../assets/maturity-not-start.svg" />
                  <img alt="more" class="status-icons mr-02 pr-st-icon" v-if="iconStaus == 'On Track'"
                    src="../../../assets/blue-tick.svg" />
                  <img alt="more" class="status-icons mr-02 pr-st-icon" v-if="iconStaus == 'Off Track'"
                    src="../../../assets/off-track.svg" />
                  <img alt="more" class="status-icons mr-02 pr-st-icon" v-if="iconStaus == 'Completed'"
                    src="../../../assets/maturity-cmpl.svg" />
                  <img alt="more" class="status-icons mr-02 pr-st-icon" v-if="iconStaus == 'Not Sustained'"
                    src="../../../assets/yellow-tick.svg" />
                  <img alt="more" class="status-icons mr-02 pr-st-icon" v-if="iconStaus == 'Deleted'"
                    src="../../../assets/deleted.svg" />

                  {{ this.plantAssessmentStatus }}
                </label>
              </div>
              <div class="status-col-2">
                <b-button v-b-modal.takeAssessmentConfirmation @click="plantStatusButtonClick('Completed')" pill
                  :disabled="enableCompletedButton || !plantSelfAssessmentPersona || !isPrincipleActive || hasInprogessAction"
                  variant="outline-secondary"
                  class="marginTop-minus-2 save-btn primary-color page-heading-tab status-btn">
                  Completed
                </b-button>
                <b-button v-b-modal.takeAssessmentConfirmation @click="plantStatusButtonClick('Not Sustained')" pill
                  variant="outline-secondary"
                  class="marginTop-minus-2 save-btn primary-color page-heading-tab status-btn status-btn-2"
                  :disabled="notSustainedCheck || !plantSelfAssessmentPersona || !isPrincipleActive">
                  Not Sustained
                </b-button>
                <b-button v-b-modal.takeAssessmentConfirmation
                  v-show="this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) || this.isUserAllowedToCalibrate == 'Y'"
                  @click="plantStatusButtonClick('Not Started')" pill variant="outline-secondary"
                  class="marginTop-minus-2 save-btn primary-color page-heading-tab status-btn status-btn-3"
                  :disabled="enableNotStartedButton || !isPrincipleActive">
                  Not Started
                </b-button>
              </div>
            </div>
            <div class="styled-input wide">
              <textarea :disabled="!plantSelfAssessmentPersona || !isPrincipleActive" class="review-box form-control"
                v-model="commentSelftAssessment"></textarea>
              <label class="page-heading-tab" :class="{ 'comment-lable': commentSelftAssessment }"> Add comment </label>
            </div>
            <div class="other-block">
              <div class="fileinputs calender-input self-assessment-dummy file-con">
                <label for="file-input-plant" class="form-label page-heading-dropdown lable-text blue-text"> Attachments (
                  Max. allowed 10 MB ) </label>
                <input :disabled="!plantSelfAssessmentPersona || !isPrincipleActive" id="file-input-plant" type="file"
                  class="file calender-input file-con" placeholder="NO"
                  @change="(e) => onChangeListener(e.target, 'Plant')" />
                <div class="fakefile" :class="{
                    'disabled-style-label': !plantSelfAssessmentPersona,
                    'disabled-style-label': !isPrincipleActive
                  }">
                  <input :disabled="!plantSelfAssessmentPersona || !isPrincipleActive" id="file-name"
                    class="fakefileinput" placeholder="No file chosen" />
                  <span class="fakefilespan">Browse</span>
                </div>
                <label class="validation-message-style">{{ this.fileErrorMessage }}</label>
              </div>

              <ul class="uploaded-items"></ul>
            </div>
            <div class="rw-act-con">
              <b-button :disabled="!plantSelfAssessmentPersona || !isPrincipleActive" @click="clearPlantApproval" pill
                variant="outline-secondary" class="cancel-btn primary-color page-heading-tab">Clear</b-button>
              <b-button :disabled="!plantSelfAssessmentPersona || !isPrincipleActive" @click="savePlantApproval" pill
                variant="outline-secondary" class="save-btn primary-color page-heading-tab">Save</b-button>
            </div>
            <div class="act-tal-con">
              <label class="headr-bar-menu">History</label>
              <b-table id="asmt-history-plant" :class="historyPlantItems != 0 ? '' : ''" responsive hover
                :items="historyPlantItems" :fields="historyPlantItemsFields" :per-page="perPageSelf"
                :current-page="currentPageSelf">
                <template #cell(attachment)="row">
                  <img v-if="row.item.attachment === true" src="../../../assets/attachment.svg"
                    alt="It has an attachment" />
                </template>
                <template #cell(View)="row">
                  <b-button ref="btnShow" id="viewPlant" class="asmt-bt button-sm" pill variant="outline-primary"
                    @click="viewPlantHistory(row.item)"> View </b-button>
                </template>
              </b-table>
              <div v-if="historyPlantItems.length > 5" class="row pagination-con">
                <b-pagination class="pagination-align record-info" v-model="currentPageSelf" :total-rows="totalSelfItems"
                  :per-page="perPageSelf" aria-controls="asmt-history-plant" prev-text="Back" next-text="Next"
                  first-text="First" last-text="Last"></b-pagination>
                <div class="per-page-cont record-info">
                  <select v-model="perPageSelf" class="per-page" aria-label="Default select example">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="50">50</option>
                  </select>
                  Per page
                </div>
              </div>

              <div v-if="!historyPlantItems.length" class="no-data">
                <img alt="no data" src="../../../assets/No_Data.svg" />
                <span class="ed-date">No Records Found</span>
              </div>
            </div>
          </div>
          <div class="col-6 review-col" v-if="secondPartyVisibility">
            <div class="page-heading-tab take-assessment-page-heading-tab-bottom-padding">Global Validation</div>
            <div class="status-con marginBottom-2">
              <div class="status-col-1">
                <label class="page-content-regular">Current Status: </label>&nbsp;
                <label class="take-assessment-page-heading-tab-font-weight">{{ this.regionalAssessmentStatus }}</label>
              </div>
              <div class="status-col-2">
                <b-button v-b-modal.takeAssessmentConfirmation @click="regionalStatusButtonClick('Approved')" pill
                  variant="outline-secondary" class="marginTop-minus-2 save-btn primary-color page-heading-tab status-btn"
                  :disabled="this.plantAssessmentCompleted || !secondaryAssessmentPersona || this.regionalAssessmentStatus == 'Approved' || !isPrincipleActive">
                  Approved
                </b-button>
                <b-button v-b-modal.takeAssessmentConfirmation @click="regionalStatusButtonClick('Not Approved')" pill
                  variant="outline-secondary"
                  class="marginTop-minus-2 save-btn primary-color page-heading-tab status-btn status-btn-2"
                  :disabled="this.plantAssessmentCompleted || !secondaryAssessmentPersona || !isPrincipleActive">
                  Not Approved
                </b-button>
              </div>
            </div>
            <div class="styled-input wide">
              <textarea :disabled="!secondaryAssessmentPersona || !isPrincipleActive" class="review-box form-control"
                v-model="commentSecondAssessment"></textarea>
              <label class="page-heading-tab" :class="{ 'comment-lable': commentSecondAssessment }">
                Add comment
              </label>
            </div>
            <div class="other-block">
              <div class="fileinputs calender-input secondary-party-dummy file-con">
                <label for="file-input" class="form-label page-heading-dropdown lable-text blue-text">
                  Attachments ( Max. allowed 10 MB )
                </label>
                <input :disabled="!secondaryAssessmentPersona || !isPrincipleActive" id="file-input" type="file"
                  class="file calender-input file-con" placeholder="NO" max-file-size="2"
                  @change="(e) => onChangeListener(e.target, 'Second')" />
                <div class="fakefile" :class="{
                    'disabled-style-label': !secondaryAssessmentPersona,
                    'disabled-style-label': !isPrincipleActive
                  }">
                  <input :disabled="!secondaryAssessmentPersona || !isPrincipleActive" id="file-name"
                    class="fakefileinput" placeholder="No file chosen" />
                  <span class="fakefilespan">Browse</span>
                </div>

                <label class="validation-message-style">{{ this.fileErrorMessageSecond }}</label>
              </div>
              <ul class="uploaded-items"></ul>
            </div>
            <div class="rw-act-con">
              <b-button :disabled="!secondaryAssessmentPersona || !isPrincipleActive" @click="clearSecondApproval" pill
                variant="outline-secondary" class="cancel-btn primary-color page-heading-tab">Clear</b-button>
              <b-button :disabled="!secondaryAssessmentPersona || !isPrincipleActive" @click="saveSecondApproval()" pill
                variant="outline-secondary" class="save-btn primary-color page-heading-tab">
                Save
              </b-button>
            </div>
            <div class="act-tal-con">
              <label class="headr-bar-menu">History</label>
              <b-table id="asmt-history-regional" responsive hover :items="historySecomdPartyItems"
                :fields="historySecondItemsFields" :per-page="perPageSec" :current-page="currentPageSec">
                <template #cell(attachment)="row">
                  <img v-if="row.item.attachment === true" src="../../../assets/attachment.svg"
                    alt="It has an attachment" />
                </template>
                <template #cell(View)="row">
                  <b-button id="viewPlant" class="asmt-bt button-sm" pill variant="outline-primary"
                    @click="viewPlantHistory(row.item)"> View </b-button>
                </template>
              </b-table>
              <div v-if="historySecomdPartyItems.length > 5" class="row pagination-con">
                <b-pagination class="pagination-align record-info" v-model="currentPageSec" :total-rows="totalSecItems"
                  :per-page="perPageSec" aria-controls="asmt-history-regional" prev-text="Back" next-text="Next"
                  first-text="First" last-text="Last"></b-pagination>
                <div class="per-page-cont record-info">
                  <select v-model="perPageSec" class="per-page" aria-label="Default select example">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="50">50</option>
                  </select>
                  Per page
                </div>
              </div>
              <div v-if="!historySecomdPartyItems.length" class="no-data">
                <img alt="no data" src="../../../assets/No_Data.svg" />
                <span class="ed-date">No Records Found</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="action-item-con margin-top-1em">
        <div class="d-flex">
          <div class="width-50 action_label">Action Items</div>

          <div class="add-action width-50" style="display: flex; justify-content: flex-end">
            <div class="plant-card">
              <div class="info-card-lable">Committed Date :{{ practiceCommittedDate | dateFormater }}</div>
            </div>
            <div class="plant-card">
              <div class="info-card-lable">Completed Date :{{ practiceCompletionDate | dateFormater }}</div>
            </div>
            <div style="margin-left: 10px">
              <!-- <b-button :disabled="
                !editAddActionPersona ||
                !isPrincipleActive ||
                this.plantAssessmentStatus == 'Completed'
              " pill class="add-action-btn ft-14 btn-action" @click="newAction">
                Add Action
              </b-button> -->
              <b-button
                :disabled="!editAddActionPersona || !isPrincipleActive || !isForAddActionCalibration(this.plantAssessmentStatus)"
                pill class="add-action-btn ft-14 btn-action" @click="newAction">
                Add Action
              </b-button>
            </div>
          </div>
        </div>
        <div class="act-tal-con">
          <b-table id="asmt-act" class="" responsive hover :items="items" :fields="fields">
            <template #cell(actionDescription)="row">
              <div>{{ stringShortner(row.item.actionDescription) }}</div>
            </template>

            <template #cell(committedDate)="row">
              <div class="dateCol">
                {{ row.item.committedDate | dateFormater }}
              </div>
            </template>
            <template #cell(completionDate)="row">
              <div class="dateCol">
                {{ row.item.completionDate | dateFormater }}
              </div>
            </template>

            <template #cell(custom_column)="row">
              <div class="mm-menu" style="width: 2rem">
                <div class="dropdown-custome dd-menu-items maturity-dd dropstart" style="width: 2rem">
                  <button class="btn btn-secondary" type="button" id="dropdownList" data-bs-toggle="dropdown"
                    aria-expanded="false" style="padding: 0; margin: 0">
                    <img alt="more" id="dropdownList" @click="actionID = row.item.actionId"
                      src="../../../assets/More.svg" />
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownList" style="text-align: left">
                    <li @click="(actionID = row.item.actionItemId), viewAction()">
                      <button style="background: none; border: none">View</button>
                    </li>
                    <li>
                      <!-- <button @click="
                        (actionID = row.item.actionItemId), editAction()
                      " id="edit-li"
                        :disabled="row.item.status == 'Completed' || !editAddActionPersona || !isPrincipleActive">
                        Edit
                      </button> -->
                      <button @click="(actionID = row.item.actionItemId), editAction()" id="edit-li"
                        :disabled="!editAddActionPersona || !isPrincipleActive || !isForEditActionCalibration(row.item.status)">
                        Edit
                      </button>
                    </li>
                    <li>
                      <button v-b-modal.takeAssessmentConfirmation
                        @click="(actionID = row.item.actionItemId), deleteAction('DeleteAction', row.item.actionName, row.item.actionDescription)"
                        id="edit-li">
                        Delete
                      </button>
                    </li>
                    <!-- :disabled="!editAddActionPersona || 
                          !isPrincipleActive ||
                          !isForEditActionCalibration(row.item.status)" -->
                  </ul>
                </div>
              </div>
            </template>
          </b-table>
          <div v-if="!action_item.length" class="no-data">
            <img alt="no data" src="../../../assets/No_Data.svg" />
            <span class="ed-date">No Records Found</span>
          </div>
        </div>
        <div class="backBtnBlock">
          <b-button pill class="back-btn btn-action" @click="discardAssessment()"> Back </b-button>
        </div>
      </div>
    </div>
    <ViewHistoryItemDetails v-bind:selectedItems="selectedViewItem" />
    <ConfirmationPopup v-bind:selectedItems="passingConfirmation" v-bind:closeApproval="closeApproval" />
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import ApiCalls from "../../../common/api/apiCalls";
import Api from "../../../common/constants/api";
import Loader from "../../../components/common/Loader.vue";
import ViewHistoryItemDetails from "./ViewHistoryItemDetails.vue";
import moment from "moment";
import Role from "../../../common/constants/roles";
import ConfirmationPopup from "./ConfirmationPopup.vue";
import Helper from "../../../common/helper/";
import axios from "axios";
export default {
  name: "TakeAssessment",
  components: {
    Breadcrumb,
    Loader,
    ViewHistoryItemDetails,
    ConfirmationPopup
  },
  data() {
    return {
      roles: Role,
      //documentName: "",
      isUserAllowedToCalibrate: "N",
      principleNumber: 1,
      principleList: [],
      perPageSelf: 5,
      currentPageSelf: 1,
      checkConfirmation: false,
      perPageSec: 5,
      currentPageSec: 1,
      passingConfirmation: {},
      //levelId: 0,
      actionID: "",
      actionOwnerName: "",
      actionTargetDate: "",
      actionCompleteDate: "",
      actionDesc: "",
      showLoader: true,
      targetActions: "",
      committedActions: "",
      completedActions: "",
      secondPartyVisibility: true,
      selectedViewItem: null,
      fileErrorMessage: "",
      commentSelftAssessment: "",
      commentSecondAssessment: "",
      fileErrorMessageSecond: "",
      levelId: 0,
      isLinkToUpdatedPractice: 0,
      practiceCommittedDate: "",
      practiceCompletionDate: "",
      action_item: [],
      plantAssessmentStatusUpdate: "",
      plantAssessmentStatus: "",
      plantAssessmentCompleted: "",
      notSustainedCheck: false,
      regionalAssessmentStatusUpdate: "",
      regionalAssessmentStatus: "",
      practiseDetailsData: [],
      attachmentUrls: [],
      plantAttachmentURL: "",
      plantAttachmentType: "",
      regionalAttachmentURL: "",
      regionalAttachmentType: "",
      practiceDescription: "",
      guidelineDesc: "",
      targetLevel: 0,
      practiseIdData: 0,
      principleId: 1,
      pillar: 1,
      practiceId: 1,
      selected: 0,
      completedSteps: 75,
      totalSteps: 100,
      plantId: "",
      pillarId: "",
      principle_action_item: [],
      levelName: "",
      principleName: "",
      statusId: 6,
      onchangePrinciple: "",
      // principleId:'',
      actionName: "",
      historySecondItemsFields: [
        {
          key: "updatedBy",
          label: "Updated By",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "dateAndTime",
          label: "Date & Time",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "attachment",
          label: "",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "View",
          label: "",
          tdClass: "border border-0",
          sortable: false
        }
      ],
      historyPlantItemsFields: [
        {
          key: "updatedBy",
          label: "Updated By",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "dateAndTime",
          label: "Date & Time",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "attachment",
          label: "",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "View",
          label: "",
          tdClass: "border border-0",
          sortable: false
        }
      ],
      historyPlantItems: [],
      historySecomdPartyItems: [],
      fields: [
        {
          key: "actionItemId",
          thClass: "d-none",
          tdClass: "d-none"
        },
        {
          key: "actionName",
          label: "#",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "actionDescription",
          label: "SMART Action",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "ownerName",
          label: "Owner",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "committedDate",
          label: "Committed Date",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "completionDate",
          label: "Completion Date",
          tdClass: "border border-0",
          sortable: false
        },
        {
          key: "status",
          label: "Status",
          tdClass: "border border-0",
          sortable: false
        },

        {
          key: "custom_column",
          label: " ",
          tdClass: "border border-0",
          sortable: false
        }
      ],
      items: [
        {
          isActive: true,
          actionItemId: "123",
          actionName: "1.1.a",
          actionDescription: "Complete self Assement",
          ownerName: "Jone Dode",
          committedDate: "01/12/2022",
          completionDate: "-",
          status: "In Progress"
        }
      ],
      iconStaus: "",
      hasInprogessAction: false,
      isMajorChange: true,
      isPrincipleActive: false
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 1
    });
    this.$store.dispatch("breadcrumb", {
      title: "Assessment",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Maturity Model Assessment / ",
          redirect: "/assessment",
          primary: true
        },
        { name: "Assessment", primary: false }
      ]
    });
  },
  created() {
    console.log("this.$route.params");
    console.log(this.$route.params);
    this.plantId = this.$route.params.id;
    this.levelId = this.$route.params.levelId ? this.$route.params.levelId : 0;
    this.pillarId = this.$route.params.ids;
    this.pillarName = this.$route.params.pillarName;
    this.plantName = this.$route.params.plantName;

    let chkIsLinkonUpdatedPrinciple = this.$route.params.principle.toString();
    let isLinkonUpdatedPriniciple = chkIsLinkonUpdatedPrinciple.indexOf("&");
    if (isLinkonUpdatedPriniciple === -1) {
      this.principleId = this.$route.params.principle ? this.$route.params.principle : 0;
      console.log("this.principleId: " + this.principleId);
    } else {
      let indx = chkIsLinkonUpdatedPrinciple.indexOf("&");
      let principleValue = chkIsLinkonUpdatedPrinciple.substring(indx + 1);
      let valArray = principleValue.split(",");
      this.principleId = valArray[0];
      this.isLinkToUpdatedPractice = valArray[1];
      console.log("this.principleId: " + this.principleId);
      console.log("this.isLinkToUpdatedPractice: " + this.isLinkToUpdatedPractice);
    }

    console.log("PlantID: " + this.plantId);
    console.log("PlantName: " + this.plantName);
    console.log("PillarID: " + this.pillarId);
    console.log("PillarName: " + this.pillarName);
    console.log("Last Modified PrincipleId: " + this.principleId);
    console.log("Last Modified LevelId: " + this.levelId);
    this.$store.dispatch("breadcrumb", {
      title: "Assessment - " + this.plantName,
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Maturity Model Assessment /",
          redirect: "/assessment",
          primary: true
        },
        { name: "Assessment", primary: false }
      ]
    });
    /*}*/

    if (this.isLinkToUpdatedPractice == 1) {
      this.getPrincipleDataLastUpdated();
      this.historyPlantItems = [];
      this.historySecomdPartyItems = [];
      this.getApprovalDetails();
      this.getCommittedAndCompletedDate();
      this.getActionsCountForAssessment();
    } else {
      this.getPrincipleData();
      this.historyPlantItems = [];
      this.historySecomdPartyItems = [];
      this.getApprovalDetails();
      this.getCommittedAndCompletedDate();
      this.getActionsCountForAssessment();
    }
    this.checkUserProfile();
  },
  computed: {
    totalSelfItems() {
      return this.historyPlantItems.length;
    },
    totalSecItems() {
      return this.historySecomdPartyItems.length;
    },
    enableNotStartedButton() {
      console.log("this.plantAssessmentStatus: " + this.plantAssessmentStatus);
      console.log("this.targetLevel: " + this.targetLevel + " | " + "this.levelid: " + this.levelId);
      if (
        this.plantAssessmentStatus === "Not Sustained" ||
        this.plantAssessmentStatus === "In Progress" ||
        this.plantAssessmentStatus === "In Progress (Major Change)" ||
        this.plantAssessmentStatus == "Completed" ||
        this.plantAssessmentStatus === "Completed (Major Change)" 
      ){
        return false; }   
      else {
        return true;
      }
    },
    enableCompletedButton() {
      if (
        this.plantAssessmentStatus === "Not Sustained" ||
        this.plantAssessmentStatus === "In Progress" ||
        this.plantAssessmentStatus === "In Progress (Major Change)" ||
        this.plantAssessmentStatus === "Completed (Major Change)" ||
        (this.plantAssessmentStatus === "Not Started" && this.items.length === 0)
      )
        return false;
      else if (this.plantAssessmentStatus !== "Completed" && this.items.length > 0) {
        let index = this.items.findIndex((val) => val.status !== "Completed");
        if (index === -1) return false;
        else return true;
      } else return true;
    },
    plantSelfAssessmentPersona() {
      if (
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTSUBPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER)
      )
        return true;
      else return false;
    },
    secondaryAssessmentPersona() {
      if (this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER)) return true;
      else return false;
    },
    actionItemsPersonaCheck() {
      if (this.$oidc.userProfile.roles.includes(this.roles.CORPORATE) || this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER)) return false;
      else return true;
    },

    editAddActionPersona() {
      if (
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTSUBPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.USER) ||
        this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER)
      )
        return true;
      else return false;
    }
  },
  methods: {
    async downloadItem(obj) {
      const url = JSON.parse(JSON.stringify(obj.documentURL));
      const label = JSON.parse(JSON.stringify(obj.documentName));
      const response = await axios.get(url, { responseType: "blob" });
      // apiCalls.httpCallDetails(url, "get").then((response) => {
      console.log(response);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
      // })
    },
    checkUserProfile() {
      console.log("this.$oidc.userProfile.roles:");
      console.log(this.$oidc.userProfile.roles);
      console.log("this.$oidc.userProfile.email:");
      console.log(this.$oidc.userProfile.email);
      let currentUserEmail = this.$oidc.userProfile.email;
      ApiCalls.httpCallDetails(Api.MYPROFILE + currentUserEmail, "get").then((data) => {
        this.myDetails = data.data;
        this.isUserAllowedToCalibrate = this.myDetails.allowCalibrate;
        console.log("this.myDetails.allowCalibrate: " + this.myDetails.allowCalibrate);
      });
    },
    isForEditActionCalibration(actionStatus) {
      console.log("actionStatus: " + actionStatus);
      if (actionStatus == "Completed") {
        console.log("isUserAllowedToCalibrate: " + this.isUserAllowedToCalibrate);
        //if (this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) || this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER) ) {
        if (this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) || this.isUserAllowedToCalibrate == "Y") {
          return true; //if current user has Global Pillar role or if user has is allowed to Calibrate
        } else return false; //if not Global Pillar role
      } else return true;
    },
    isForAddActionCalibration(plantAssessmentStatus) {
      //console.log('plantAssessmentStatus: ' + plantAssessmentStatus);
      //if (plantAssessmentStatus == 'Completed')     // if practice status is completed
      //{
      console.log("isUserAllowedToCalibrate: " + this.isUserAllowedToCalibrate);
      //if (this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) || this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER) ) {
      if (this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) || this.isUserAllowedToCalibrate == "Y") {
        return true; //if current user has Global Pillar role or if user has is allowed to Calibrate
      } else return false; //if not Global Pillar role
      //}

      //else return true;       // if practice status is not yet completed
    },
    closeApproval(section) {
      this.historyPlantItems = [];
      this.historySecomdPartyItems = [];
      if (section === "plant") {
        this.clearPlantApproval();
      } else {
        this.clearSecondApproval();
      }
      this.getApprovalDetails();
      this.getActionsCountForAssessment();
      this.getCommittedAndCompletedDate();
      this.practiseDetails(this.principleId);
    },
    addMore() {
      if (this.$route.params.screen === "assessment") {
        this.$router.push("/assessment");
      } else {
        this.$router.push("/action/edit-action/" + this.plantId + "/" + this.pillarId);
      }
    },
    getActionsCountForAssessment() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.PLANTPILLARDETAILS + this.plantId, "get").then((data) => {
        let pillarDetails = data.data;
        for (const element of pillarDetails) {
          if (parseInt(element.pillarId) === parseInt(this.pillarId)) {
            this.targetActions = element.overallTargetActions;
            this.committedActions = element.totalActionCommited;
            this.completedActions = element.totalActionCompleted;
          }
        }
        this.showLoader = false;
      });
    },
    clearPlantApproval() {
      this.plantAssessmentStatusUpdate = "";
      this.commentSelftAssessment = "";
      this.plantAttachmentURL = "";
      this.plantAttachmentType = "";
      document.querySelector("div.self-assessment-dummy #file-name").value = "";
    },
    clearSecondApproval() {
      this.regionalAssessmentStatusUpdate = "";
      this.commentSecondAssessment = "";
      this.regionalAttachmentURL = "";
      this.regionalAttachmentType = "";
      document.querySelector("div.secondary-party-dummy #file-name").value = "";
    },
    regionalStatusButtonClick(statusName) {
      this.regionalAssessmentStatusUpdate = statusName;
      this.passingConfirmation = {};
      this.passingConfirmation = {
        checkConfirmation: this.commentSecondAssessment === "" && this.regionalAttachmentURL === "" ? true : false,
        plantId: parseInt(this.plantId),
        practiceId: parseInt(this.practiceId),
        comments: this.commentSecondAssessment ? this.commentSecondAssessment : "Marked as " + this.regionalAssessmentStatusUpdate,
        statusName: this.regionalAssessmentStatusUpdate !== "" ? this.regionalAssessmentStatusUpdate : this.regionalAssessmentStatus,
        attachments:
          this.regionalAttachmentURL && this.regionalAttachmentURL !== ""
            ? [
              {
                documentUrl: this.regionalAttachmentURL,
                documentType: this.regionalAttachmentType
              }
            ]
            : []
      };
    },
    plantStatusButtonClick(statusName) {
      this.plantAssessmentStatusUpdate = statusName;
      this.passingConfirmation = {};
      this.passingConfirmation = {
        checkConfirmation: this.commentSelftAssessment === "" && this.plantAttachmentURL === "" ? true : false,
        plantId: parseInt(this.plantId),
        practiceId: parseInt(this.practiceId),
        comments: this.commentSelftAssessment ? this.commentSelftAssessment : this.plantAssessmentStatusUpdate ? "Marked as " + this.plantAssessmentStatusUpdate : "",
        statusName: statusName,
        attachments:
          this.plantAttachmentURL && this.plantAttachmentURL !== ""
            ? [
              {
                documentUrl: this.plantAttachmentURL,
                documentType: this.plantAttachmentType
              }
            ]
            : []
      };

      if (this.commentSelftAssessment && this.plantAttachmentURL) this.plantAssessmentStatusUpdate = statusName;
      else {
      }
    },
    viewPlantHistory(viewAction) {
      this.selectedViewItem = viewAction.View;
      this.selectedViewItem.dateAndTime = viewAction.dateAndTime.split(",")[0];
      this.selectedViewItem["Time"] = viewAction.dateAndTime.split(",")[1];
      for (const element of this.selectedViewItem["attachments"]) {
        let row = element;
        element.documentName = decodeURIComponent(row.documentUrl.split("/")[row.documentUrl.split("/").length - 1]);
      }
      this.$root.$emit("bv::show::modal", "viewHistoryItemDetails", "#btnShow");
      //this.$refs['viewHistoryItemDetails'].show()
    },
    saveSecondApproval() {
      this.showLoader = true;
      let req = {};
      req = {
        plantId: parseInt(this.plantId),
        practiceId: parseInt(this.practiceId),
        comments: this.commentSecondAssessment ? this.commentSecondAssessment : this.regionalAssessmentStatusUpdate ? "Marked as " + this.regionalAssessmentStatusUpdate : "",
        statusName: this.regionalAssessmentStatusUpdate !== "" ? this.regionalAssessmentStatusUpdate : this.regionalAssessmentStatus,
        attachments:
          this.regionalAttachmentURL && this.regionalAttachmentURL !== ""
            ? [
              {
                documentUrl: this.regionalAttachmentURL,
                documentType: this.regionalAttachmentType
              }
            ]
            : []
      };
      if (!(this.commentSecondAssessment === "" && this.regionalAttachmentURL === "")) {
        ApiCalls.httpCallDetails(Api.TAKEASSESSMENTSECONDPARTYAPPROVAL, "post", req).then(() => {
          this.historyPlantItems = [];
          this.historySecomdPartyItems = [];
          this.getApprovalDetails();

          this.getActionsCountForAssessment();
          this.getCommittedAndCompletedDate();
          this.clearSecondApproval();
          this.showLoader = false;
          this.regionalAttachmentURL = "";
          req = {};
        });
        if (this.regionalAssessmentStatusUpdate == "Not Approved") {
          this.plantAssessmentStatusUpdate = "Not Sustained";
          this.regionalAttachmentURL = "";
          req = {};
          this.savePlantApproval();
        } else {
          this.regionalAttachmentURL = "";
          req = {};
        }
      } else {
        this.showLoader = false;
        this.regionalAttachmentURL = "";
        req = {};
      }
    },
    savePlantApproval() {
      let req = {};
      req = {
        plantId: parseInt(this.plantId),
        practiceId: parseInt(this.practiceId),
        comments: this.commentSelftAssessment ? this.commentSelftAssessment : this.plantAssessmentStatusUpdate ? "Marked as " + this.plantAssessmentStatusUpdate : "",
        statusName: this.plantAssessmentStatusUpdate !== "" ? this.plantAssessmentStatusUpdate : this.plantAssessmentStatus,
        attachments:
          this.plantAttachmentURL && this.plantAttachmentURL !== ""
            ? [
              {
                documentUrl: this.plantAttachmentURL,
                documentType: this.plantAttachmentType
              }
            ]
            : []
      };
      this.showLoader = true;
      if (!(this.commentSelftAssessment === "" && this.plantAttachmentURL === "")) {
        ApiCalls.httpCallDetails(Api.TAKEASSESSMENTSELFASSESSMENT, "post", req).then(() => {
          this.historyPlantItems = [];
          this.historySecomdPartyItems = [];
          this.getApprovalDetails();
          this.getActionsCountForAssessment();
          this.getCommittedAndCompletedDate();
          this.clearPlantApproval();
          this.showLoader = false;
          this.plantAttachmentURL = "";
          req = {};
        });
      } else {
        this.showLoader = false;
        this.plantAttachmentURL = "";
        req = {};
      }
    },
    getApprovalDetails() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.TAKEASSESMENTAPPROVALGET + this.plantId + "/" + this.practiceId, "get").then((dataJSON) => {
        this.historyPlantItems = [];
        this.historySecomdPartyItems = [];
        let historyPlantItems = [];
        historyPlantItems = dataJSON.data.selfAssesmentDetails.historyDetails;

        let historySecondPartyItems = [];
        historySecondPartyItems = dataJSON.data.secondPardyAssessmentDetails.historyDetails;
        this.plantAssessmentCompleted = dataJSON.data.selfAssesmentDetails.currentStatus === "Completed" ? false : true;
        this.notSustainedCheck = dataJSON.data.selfAssesmentDetails.currentStatus === "Not Sustained";
        this.plantAssessmentStatus = dataJSON.data.selfAssesmentDetails.currentStatus === "Practice Completed" ? "In Progress" : dataJSON.data.selfAssesmentDetails.currentStatus;
        let editStatus = this.plantAssessmentStatus.match("(Major Change)")
          ? this.plantAssessmentStatus.substring(0, this.plantAssessmentStatus.match("(Major Change)").index - 2)
          : "";
        this.plantAssessmentStatus = editStatus === "Not Sustained" || editStatus === "Not Started" ? editStatus : this.plantAssessmentStatus;
        this.regionalAssessmentStatus = dataJSON.data.secondPardyAssessmentDetails.currentStatus;
        for (let el of historyPlantItems) {
          const element = el;
          this.historyPlantItems.push({
            updatedBy: element.updatedBy,
            dateAndTime: moment(element.dateAndTime).format("DD-MMM-YYYY, LT") + " EST",
            attachment: element.attachments.length > 0 ? true : false,
            View: element
          });
        }
        this.historySecomdPartyItems = [];
        for (const element of historySecondPartyItems) {
          this.historySecomdPartyItems.push({
            updatedBy: element.updatedBy,
            dateAndTime: moment(element.dateAndTime).format("DD-MMM-YYYY, LT") + " EST",
            attachment: element.attachments.length > 0 ? true : false,

            View: element
          });
        }
        this.showLoader = false;
      });
    },
    onChangeListener(value, section) {
      this.showLoader = true;
      ////console.log(value.files[0]);
      if (value.files[0]) {
        let reader = new FileReader();
        reader.readAsDataURL(value.files[0]);
        reader.onloadend = () => {
          const maxAllowedSize = 10 * 1024 * 1024;
          if (value.files[0].size > maxAllowedSize) {
            if (section === "Plant") this.fileErrorMessage = "Please upload file less than 10MB";
            else this.fileErrorMessageSecond = "Please upload file less than 10MB";
            this.showLoader = false;
          } else {
            if (section === "Plant") {
              this.fileErrorMessage = "";
              document.querySelector("div.self-assessment-dummy #file-name").value = value.files[0].name;
            } else {
              this.fileErrorMessageSecond = "";
              document.querySelector("div.secondary-party-dummy #file-name").value = value.files[0].name;
            }
            let imageSet = [];
            imageSet.push(reader.result);
            let newImageSet = [];
            newImageSet.push(value.files[0]);
            let timeStamp = new Date().toISOString();
            let str = value.files[0].name;
            let type = value.files[0].type ? "." + value.files[0].type.split("/")[1] : value.files[0].name.substring(str.lastIndexOf("."), str.length - 1);
            let fileName = str.substring(str.lastIndexOf("."), 0) + "_" + timeStamp + type;
            console.log(fileName);
            let arr = str.split(".");

            if (value.files[0].name.split(".").length > 2) {
              arr = "";
              for (let itr = 0; itr <= value.files[0].name.split(".").length - 2; itr++) {
                if (arr === "") {
                  arr = value.files[0].name.split(".")[itr];
                } else {
                  arr = arr + "." + value.files[0].name.split(".")[itr];
                }
              }
              fileName = arr + "_$^#^$_" + timeStamp + "_$^#^$_." + value.files[0].name.split(".")[value.files[0].name.split(".").length - 1];
            } else {
              fileName = arr[arr.length - 2] + "_$^#^$_" + timeStamp + "_$^#^$_." + arr[arr.length - 1];
            }
            let fileUploadFormData = new FormData();
            fileUploadFormData.append("imgsrc", newImageSet[0], fileName);
            //,documentName
            this.showLoader = true;

            ApiCalls.httpCallDetails(Api.CREATEDOCUMENT, "post", fileUploadFormData).then((data) => {
              this.showLoader = false;
              if (section === "Plant") {
                this.plantAttachmentURL = "";
                this.plantAttachmentType = "";
              } else {
                this.regionalAttachmentURL = "";
                this.regionalAttachmentType = "";
              }
              let name = value.files[0].name;
              this.name = name.split(".");
              if (section === "Plant") this.plantAttachmentURL = data.message;
              else this.regionalAttachmentURL = data.message;
              let name1 = value.files[0].name;
              name1 = name1.split(".");
              if (section === "Plant") {
                this.plantAttachmentURL = data.message;
                this.plantAttachmentType = name1[name1.length - 1];
              } else {
                this.regionalAttachmentURL = data.message;
                this.regionalAttachmentType = name1[name1.length - 1];
              }
              this.showLoader = false;
            });
          }
        };
      }
    },
    practiseDetails(selectObject) {
      let index = this.principleList.findIndex((val) => val.principleId == this.principleId);
      this.isPrincipleActive = this.principleList[index].active.toUpperCase() === "Y" ? true : false;
      this.showLoader = true;
      let value = selectObject;
      this.attachmentUrls = [];
      ApiCalls.httpCallDetails(Api.PRACTISEDETAILS + value + "/" + this.plantId, "get").then((dataJSON) => {
        this.practiseDetailsData = dataJSON.data.practiceList;
        let index = this.principleList.findIndex((val) => val.principleId == value);
        this.principleNumber = index !== -1 ? this.principleList[index].principleNumber : 1;
        this.principleName = index !== -1 ? this.principleList[index].principleName : "";
        setTimeout(() => {
          if (this.$route.params.screen == "assessment") {
            if (Helper.getAssessmentStatus() == "viewAction") {
              this.handleRadioChange(this.levelId);
            } else {
              this.handleRadioChange(dataJSON.data.principleTargetMaturityLevel);
            }
          } else {
            if (this.onchangePrinciple) this.handleRadioChange(dataJSON.data.principleTargetMaturityLevel);
            else {
              this.handleRadioChange(this.levelId);
            }
          }
          this.targetLevel = dataJSON.data.principleTargetMaturityLevel;
          this.showLoader = false;
          this.historyPlantItems = [];
          this.historySecomdPartyItems = [];
          this.getApprovalDetails();
        }, 300);
      });
      this.getCommittedAndCompletedDate();
    },
    submitAssessment() {
      this.showLoader = true;
      let requestPayload = {
        PlantId: 1,
        PracticeId: this.practiseIdData,
        ObservedCondition: document.getElementById("observedCondition").value,
        DocumentUrl: "https://gydevpostorage.blob.core.windows.net/po-test-blob-container/sample_assessment_doc.jpg",
        DocumentType: "image"
      };
      ApiCalls.httpCallDetails(Api.SUBMITPRACTISEDETAILS, "post", requestPayload).then(() => (this.showLoader = false));
    },
    stringShortner(str) {
      if (str.length > 25) {
        str = str.substr(0, 35);
        str += "...";
      }
      return str;
    },
    discardAssessment() {
      this.$router.push("/assessment");
    },
    handleRadioChange(id) {
      this.attachmentUrls = [];
      this.getCommittedAndCompletedDate();
      for (let i = 0; i <= this.targetLevel; i++) {
        let radio = document.getElementById("Level" + i);
        radio.classList.add("live-level");
      }
      for (let i = 0; i <= 5; i++) {
        let radio = document.getElementById("Level" + i);
        radio.classList.remove("active-level");
      }

      this.guidelineDesc = this.practiseDetailsData[parseInt(id)] && this.practiseDetailsData[parseInt(id)].guideline ? this.practiseDetailsData[parseInt(id)].guideline : "";
      this.attachmentUrls = this.practiseDetailsData[parseInt(id)].attachmentUrls;
      this.practiceDescription =
        this.practiseDetailsData[parseInt(id)] && this.practiseDetailsData[parseInt(id)].practiceDescription ? this.practiseDetailsData[parseInt(id)].practiceDescription : "";
      if (id < 3) {
        this.secondPartyVisibility = false;
      } else {
        this.secondPartyVisibility = true;
      }
      let radioElement = document.getElementById("Level" + id);
      radioElement.classList.add("active-level");
      this.levelId = this.practiseDetailsData[parseInt(id)].levelId;
      this.practiceId = this.practiseDetailsData[parseInt(id)].practiceId;

      this.historyPlantItems = [];
      this.historySecomdPartyItems = [];
      let statusName = this.practiseDetailsData[parseInt(id)].statusName;
      this.iconStaus = statusName.match("(Major Change)") ? statusName.substring(0, statusName.match("(Major Change)").index - 2) : statusName;
      this.getApprovalDetails();
      this.getCommittedAndCompletedDate();
    },
    getPrincipleDataLastUpdated() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.PRINCIPLELIST + this.pillarId, "get").then((dataJSON) => {
        this.principleList = [];
        let data = dataJSON.data;
        this.principleList = data;
        for (let el of data) {
          let principleName = el.principleName;
          let principleid = el.principleNumber;
          let element = document.createElement("OPTION");
          element.setAttribute("value", el.principleId);
          let t = document.createTextNode(principleid + ". " + principleName);
          element.appendChild(t);
          document.getElementById("pillar").appendChild(element);
        }

        console.log("data.length  && this.$route.params.screen value");
        console.log("data: ");
        console.log(data);
        console.log("data.length: " + data.length);
        console.log("this.$route.params.screen: " + this.$route.params.screen);
        if (data.length > 0 && this.$route.params.screen == "assessment") {
          console.log("data.length > 0 && this.$route.params.screen == assessment");

          if (Helper.getAssessmentStatus() == "viewAction") {
            console.log("Helper.getAssessmentStatus() == viewAction");
          } else {
            console.log("Helper.getAssessmentStatus() != viewAction");
            console.log("PrincipleID: " + this.principleId);
            let princId = this.principleId;
            let newData = data.filter(function (el) {
              return el.principleId == princId;
            });
            this.practiseDetails(princId);
            console.log("newData");
            console.log(newData);
          }
        }
        // else {
        //   console.log("data.length not greater than 0, $route.params.screen not equal to assessment");
        //   console.log("data.length: " + data.length + " | this.$route.params.screen: " + this.$route.params.screen);
        // }

        this.historyPlantItems = [];
        this.historySecomdPartyItems = [];
        this.getApprovalDetails();
        this.getActionsCountForAssessment();
        this.getCommittedAndCompletedDate();
        this.isLinkToUpdatedPractice = 0;
        this.showLoader = false;
      });
    },
    async getPrincipleData() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.PRINCIPLELIST + this.pillarId, "get").then((dataJSON) => {
        this.principleList = [];
        let data = dataJSON.data;
        this.principleList = data;
        for (let el of data) {
          let principleName = el.principleName;
          let principleid = el.principleNumber;
          let element = document.createElement("OPTION");
          element.setAttribute("value", el.principleId);
          let t = document.createTextNode(principleid + ". " + principleName);
          element.appendChild(t);
          document.getElementById("pillar").appendChild(element);
        }

        if (data.length > 0 && this.$route.params.screen == "assessment") {
          if (Helper.getAssessmentStatus() == "viewAction") {
            this.principleId = Helper.getAssessmentPrincipleId();

            this.levelId = Helper.getlevelId();
            this.practiseDetails(this.principleId);
          } else {
            this.principleId = data[0].principleId;
            this.practiseDetails(data[0].principleId);
          }
        } else {
          if (this.$route.params.screen == "action" && Helper.getAssessmentStatus() == "viewAction") {
            this.principleId = Helper.getAssessmentPrincipleId();

            this.levelId = Helper.getlevelId();
            this.practiseDetails(this.principleId);
          } else {
            this.practiseDetails(this.principleId);
          }
        }
        this.historyPlantItems = [];
        this.historySecomdPartyItems = [];
        this.getApprovalDetails();
        this.getActionsCountForAssessment();
        this.getCommittedAndCompletedDate();
        this.isLinkToUpdatedPractice = 0;
        this.showLoader = false;
      });
    },
    back() {
      this.$router.push("/assessment");
    },
    newAction() {
      Helper.setAssessmentStatus("assessment");
      // /:plantId/:pillarId/:principleId/:practiceId
      // let item = this.actionID;
      // let lastItem = item.split(".").pop();
      //  this.actionID = lastItem;
      //  //console.log("this.actionID "+this.actionID);
      this.$router.push(
        "/add-new-action/" +
        this.plantId +
        "/" +
        this.pillarId +
        "/" +
        this.principleId +
        "/" +
        this.practiceId +
        "/" +
        "add" +
        "/" +
        this.levelId +
        "/" +
        this.pillarName +
        "/" +
        this.plantName +
        "/" +
        parseInt(this.statusId) +
        "/" +
        "take-assessment"
      );
    },
    viewAction() {
      Helper.setAssessmentStatus("viewAction");
      Helper.setAssessmentPrincipleId(this.principleId);

      Helper.setlevelId(this.levelId);
      this.$router.push("/view-action/assessment/" + this.actionID + "/" + this.plantId + "/" + this.pillarId);
    },
    deleteAction(statusName, actionName, actionDesc) {
      console.log("delete Actionid: " + this.actionID);
      console.log("this.plantId: " + this.plantId + "| plantName: " + this.plantName);
      console.log("this.pillarName: " + this.pillarName);
      console.log("parseInt(this.practiceId): " + parseInt(this.practiceId));
      console.log("this.actionName: " + this.principleNumber + "." + this.levelId + " " + actionDesc);

      this.passingConfirmation = {};
      this.passingConfirmation = {
        checkConfirmation: true,
        plantId: parseInt(this.plantId),
        practiceId: parseInt(this.practiceId),
        comments: "",
        statusName: statusName,
        actionid: this.actionID,
        //actionDesc: this.principleNumber + '.' + this.levelId + this.actionName + ' ' + this.actionDescription,
        actionNumber: actionName,
        actionDesc: actionDesc
      };
    },
    editAction() {
      Helper.setAssessmentStatus("assessment");
      // /:plantId/:pillarId/:principleId/:practiceId
      // let item = this.actionID;
      // let lastItem = item.split(".").pop();
      //  this.actionID = lastItem;

      this.$router.push(
        "/add-new-action/" +
        this.plantId +
        "/" +
        this.pillarId +
        "/" +
        this.principleId +
        "/" +
        parseInt(this.practiceId) +
        "/" +
        this.actionID +
        "/" +
        this.levelId +
        "/" +
        this.pillarName +
        "/" +
        this.plantName +
        "/" +
        parseInt(this.statusId) +
        "/" +
        "assessment-action"
      );
    },
    // editActionButton() {
    //       // /:plantId/:pillarId/:principleId/:practiceId
    //       this.$router.push(
    //         "/edit-action/" +
    //         this.actionDesc +"/"+this.actionTargetDate +"/"+this.actionCompleteDate+"/"+this.actionOwnerName,
    //       );
    //     },

    getCommittedAndCompletedDate() {
      this.showLoader = true;
      this.action_item = [];
      if (this.plantId != 0 && this.principleId != 0) {
        let url = Api.GETACTIONITEMPLANTANDPRINCIPLEID + this.plantId + "/" + this.principleId + "/" + this.levelId;

        let arr = [];

        ApiCalls.httpCallDetails(url, "get").then((data) => {
          if (data.success === true) {
            this.showLoader = false;
            this.practiceCommittedDate = data.data.practiceCommittedDate;
            this.practiceCompletionDate = data.data.practiceCompletionDate;
            let principleId = this.principleId;
            let principleNumber = this.principleNumber;
            let levelId = this.levelId;
            this.action_item = data.data.actionItemsList;
            this.hasInprogessAction = false;
            let hasInprogessAction = false;
            console.log(principleId);
            if (this.action_item.length > 0) {
              this.action_item.forEach(function (element) {
                let actionItemId = element.actionItemId;
                let actionName = element.actionName;
                let actionDescription = element.actionDescription;
                let ownerName = element.ownerName;
                let committedDate = element.committedDate;
                let completedDate = element.completionDate;
                let status = element.status;
                let obj = {
                  actionItemId: actionItemId,
                  actionName: principleNumber + "." + levelId + "." + actionName,
                  actionDescription: actionDescription,
                  ownerName: ownerName,
                  committedDate: committedDate,
                  completionDate: completedDate,
                  status: status
                };
                if (status.toUpperCase() == "IN PROGRESS") hasInprogessAction = true;
                arr.push(obj);
              });
              this.items = arr;
            } else {
              arr = [];
              this.action_item = [];
              this.items = [];
            }
            this.hasInprogessAction = hasInprogessAction;
          } else {
            arr = [];
            this.action_item = [];
            this.items = [];
          }
          this.showLoader = false;
        });
      }
    }
  },
  getLevelStatus(id) {
    return this.practiseDetailsData[parseInt(id)].statusName;
  }
};
</script>
<style scoped>
.disabled-style-label {
  opacity: 0.65;
}

.marginRight16Percentage {
  margin-right: 5%;
}

.col-5 {
  max-width: 55%;
}

.width100 {
  width: 100%;
}

.marginTop-minus-2 {
  margin-top: -2% !important;
}

.marginBottom-2 {
  margin-bottom: 2% !important;
}

.lable-text {
  position: absolute;
  background: #fff;
  margin: -1.2rem 0 0 1rem;
  padding-right: 5px;
  padding-left: 5px;
}

.calender-input {
  height: 52px;
  width: 59.25rem;
  font-size: 1.166rem;
  font-family: "BarlowR", sans-serif;
}

@media only screen and (max-width: 1365px) {
  .calender-input {
    height: 52px;
    width: 39.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1380px) {
  .calender-input {
    height: 52px;
    width: 43.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (max-width: 1680px) and (min-width: 1381px) {
  .calender-input {
    height: 52px;
    width: 55.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (min-width: 1681px) and (max-width: 1920px) {
  .calender-input {
    height: 52px;
    width: 64.75rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (min-width: 1921px) {
  .calender-input {
    height: 52px;
    width: 68.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

.other-block {
  /* display: grid;
  justify-content: end; */
  margin-top: 2rem;
}

div.fileinputs {
  position: relative;
  border: 1px solid #313131;
  border-radius: 4px;
}

div.fakefile {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.fakefileinput {
  height: 100%;
  width: 80%;
  border: transparent;
  border-right: 1px solid #313131;
  color: #313131;
  font: 1.166rem "BarlowR", sans-serif;
  padding: 5px;
  margin: 2px;
}

.fakefilespan {
  text-align: center;
  width: 20%;
  font: 1.166rem "BarlowSb", sans-serif;
  color: #274ba7;
}

input.file {
  position: relative;
  text-align: right;
  /* -moz-opacity:0 ;
	filter:alpha(opacity: 0); */
  opacity: 0;
  z-index: 2;
}

.container {
  position: relative;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hide the default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

#editButton:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.top-card {
  width: 7rem;
}

.plant-card {
  background: #fffce3;
  /* // height: 54px; */
  margin-left: 1.5em;
  text-align: center;
  color: #414141;
  padding: 8px;
  border-radius: 7px;
}

.action_label {
  font-family: "BarlowM", sans-serif;
  line-height: 1.5rem;
  font-size: 1.3333333333333333rem;
}

.btn-action {
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
  font-size: 1rem;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

.progress-con {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.per-text {
  color: #0f7222;
  font-weight: 600;
}

.asmt-con {
  background: #fff;
  border-radius: 6px;
  margin-top: 1em;
  padding-bottom: 1em;
}

.asmt-info {
  border-bottom: 1px solid #dedbdb;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.asmt-padding {
  margin-top: 1em;
  margin-bottom: 1em;
}

.asmt-footer-info {
  border-top: 1px solid #dedbdb;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  width: 100%;
  align-items: center;
}

.principle-dd {
  width: 30%;
  padding: 2em 2em;
  padding-bottom: 0;
}

.principal-selecct {
  height: 54px;
  border-color: #313131;
}

.level-con {
  padding: 1em;
  margin-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
  cursor: pointer;
}

.level-info {
  width: 17%;
  text-align: center;
  height: 64px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.level-border {
  display: flex;
  border: 1px solid #dedbdb;
  /* padding-top: 1em;
  padding-bottom: 1em; */
  border-radius: 6px;
}

.live-level {
  background: #eff3ff;
}

.cursorpointer {
  cursor: pointer;
}

.level-br {
  border-right: 1px solid #dedbdb;
}

.active-level {
  /* background: #006400; */
  background: #00428f;
  color: #fff;
}

.active-level:after {
  content: "";
  position: relative;
  top: 58%;
  left: -45%;
  /* margin-left: -50px; */
  width: 0;
  height: 0;
  border-top: solid 10px #00428f;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.active-level:hover:after {
  content: "";
  position: relative;
  top: 58%;
  left: -45%;
  /* margin-left: -50px; */
  width: 0;
  height: 0;
  border-top: solid 10px #d9dff3;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.prp-info {
  padding-left: 2em;
  padding-right: 2em;
}

.prp-name {
  margin-top: 3px;
}

.margin-top-1em {
  margin-top: 1em;
}

.behavoir-des {
  margin-top: 3px;
}

.attch-con {
  padding-left: 2em;
  padding-right: 2em;
}

.attch-col {
  border-radius: 6px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 5px !important;
  color: #274ba7;
}

.attch-row {
  padding-left: 1em;
  padding-right: 2em;
  margin-top: 5px;
}

.attch-icon {
  float: right;
}

.review-con {
  padding-left: 2em;
  padding-right: 2em;
}

.review-rox {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 10px;
}

.review-col {
  border: 2px solid #e2dfdf;
  border-radius: 6px;
  padding: 1em;
}

.pm-rw-con {
  margin-right: 71px;
}

.review-row {
  padding: 1em;
}

.review-type-col {
  text-align: center;
  background: #f0f7ff;
  border-radius: 6px;
  padding: 1em;
  margin-right: 10px;
}

.rw-act-con {
  text-align: right;
  margin-top: 10px;
}

.cancel-btn {
  border: 0px !important;
}

.cancel-btn:hover {
  background: #fff;
  color: #274ba7;
}

.cancel-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.save-btn {
  border-color: #869ed8 !important;
  background: #eff3ff 0% 0% no-repeat padding-box !important;
  color: #274ba7 !important;
}

.save-btn:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.cancel-btn:active {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.save-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.shortHeight {
  height: 12rem;
}

.pagination-con {
  font-size: 0.875em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0;
  align-items: center;
  margin: 0;
}

@media only screen and (max-width: 1100px) {
  .active-level:after {
    left: -27% !important;
  }
}

@media only screen and (max-width: 875px) {
  .active-level:after {
    left: -30% !important;
  }
}

@media only screen and (max-width: 575px) {
  .level-con {
    overflow-x: auto;
  }

  .level-info {
    padding: 1em;
    width: 41%;
  }

  .principle-dd {
    width: 50%;
  }

  .active-level:after {
    left: -40% !important;
  }
}

@media only screen and (min-width: 768px) {
  active-level:after {
    top: 71.5%;
    left: 61%;
    margin-left: -45px;
  }

  .review-type-col {
    max-width: 30.333333% !important;
  }

  .review-rox .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .review-rox .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .asmt-info {
    display: block !important;
  }

  .asmt-name {
    width: 100% !important;
  }

  .progress-con {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1342px) {
  .pm-rw-con {
    margin-right: 35px !important;
  }
}

@media only screen and (max-width: 1342px) {
  .pm-rw-con {
    margin-right: 35px !important;
  }
}

@media only screen and (min-width: 992px) {
  .review-rox .col-6 {
    max-width: 47% !important;
  }
}

@media only screen and (max-width: 992px) {
  .pp-rw {
    margin-top: 1em !important;
  }

  .principle-dd {
    width: 40% !important;
  }

  .pagination-align {
    justify-content: left !important;
  }
}

@media only screen and (max-width: 575px) {
  .col-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-top: 10px !important;
  }

  .col-3 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .principle-dd {
    width: 100% !important;
  }
}

.review-box:focus~label {
  color: #274ba7;
  top: -0.8rem;
  left: 1rem;
  transition: all 0.125s ease;
  background: #fff;
  padding: 0 4px;
}

.review-box:focus {
  border: 2px solid #274ba7;
}

.review-box:focus-visible {
  border: 2px solid #274ba7;
}

.styled-input {
  width: 33.3333%;
  position: relative;
}

.styled-input label {
  padding: 1.6rem;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide {
  width: 100%;
}

.review-box:focus~span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

.review-box {
  border: 1px solid #d2cbcb;
  border-radius: 6px;
  height: 5em;
  resize: none;
  padding: 1rem 1rem;
  width: 100%;
  font-size: 1rem;
  font-family: "BarlowR", sans-serif;
}

.review-com {
  margin-top: 10px;
}

.add-action {
  text-align: right;
}

.action-item-con {
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 2em;
}

.back-btn {
  background: #274ba7;
  border-color: #274ba7;
  padding: 0.6rem 1.2rem;
}

.back-btn :hover {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.back-btn :active {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action-btn {
  background: #274ba7;
  border-color: #274ba7;
}

.add-action-btn:hover {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.act-tal-con {
  margin-top: 1em;
}

.back-btn-con {
  text-align: right;
  margin-right: 15px;
}

.take-assessment-page-heading-tab-bottom-padding {
  padding-bottom: 1rem;
}

.take-assessment-page-heading-tab-font-weight {
  font-size: 1.083rem;
  font-family: "BarlowM", sans-serif;
}

.status-con {
  display: flex;
}

.status-col-1 {
  width: 50%;
}

.status-col-2 {
  width: 50%;
  text-align: right;
}

.status-btn {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.status-btn-2 {
  margin-left: 1rem;
}

.status-btn-3 {
  margin-left: 1rem;
}

.file-con {
  width: 100%;
}

#errorForComment,
#errorForUploadFile {
  color: red;
  font-family: "BarlowR", sans-serif;
}

.uploaded-items {
  list-style: none;
  padding: 0;
}

.uploaded-items-li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.li-doc-class {
  margin-bottom: 8px;
  color: #274ba7;
  font-family: "BarlowR", sans-serif;
  list-style: none;
  line-height: 1.9166666666666667rem;
  font-size: 1.1666666666666667rem;
}

#edit-li:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

#delete-li:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

#edit-li {
  width: 100%;
  text-align: left;
  border: none;
  background: none;
}

#delete-li {
  width: 100%;
  text-align: left;
  border: none;
  background: none;
}

.backBtnBlock {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 1240px) and (max-width: 1440px) {

  /* .status-con[data-v-0d8de0d6] {
    display: block !important;
  }
  .status-col-2[data-v-0d8de0d6] {
    width: 100%;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
  } */
  .status-col-1 {
    width: 54%;
  }

  .status-col-2 {
    width: 46%;
    text-align: right;
  }

  .status-btn {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1240px) {
  .status-con[data-v-0d8de0d6] {
    display: block !important;
  }

  .status-col-2[data-v-0d8de0d6] {
    width: 100%;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .status-col-1 {
    width: 54%;
  }

  .status-col-2 {
    width: 46%;
    text-align: right;
  }

  .status-btn {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }
}

@media only screen and (max-width: 665px) {
  .status-con[data-v-0d8de0d6] {
    display: block !important;
  }

  .status-col-2[data-v-0d8de0d6] {
    width: 100%;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 388px) {
  .status-btn-2 {
    margin-left: 0rem;
    margin-top: 1rem !important;
  }
}

.comment-lable {
  color: #274ba7;
  top: -0.8rem !important;
  left: 1rem !important;
  background: #fff;
  padding: 0 4px !important;
}

.mr-02 {
  margin-right: 0.2rem;
}

.ml-02 {
  margin-left: 0.2rem;
}

.pr-st-icon {
  margin-top: -0.4rem;
}
</style>
